<template>
    <div class="page recordBox">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                     @click="showList = !showList"></div>
                <div class="bg-white" v-show="showList">
                    <el-descriptions title="档案分类"></el-descriptions>
                    <el-tree class="treeData" :data="treeData" :props="defaultProps" v-loading="treeLoading"
                             ref="myTree" node-key="id" highlight-current @node-click="selRecord">
                        <div slot-scope="{ node, data }" class="omit">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <div>{{ node.label }}</div>
                            </el-tooltip>
                        </div>
                    </el-tree>
                </div>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
                <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm">
                    <el-form-item prop="year" label="年度：">
                        <el-date-picker
                                v-model="searchForm.year"
                                value-format="yyyy"
                                format="yyyy"
                                type="year"
                                placeholder="请选择年度" :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search" @click="search()" size="small">查询</el-button>
                        <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                    <el-form-item class="f_r">
                        <el-button type="primary" size="small" v-show="hasPermission('statistics:resourceList:export')"
                                   @click="exportData()"><i class="icon-piliangdaochu iconfont buIcon"/>导出全部
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="bg-white">
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            size="small"
                            height="calc(100vh - 296px)"
                            class="table">
                        <el-table-column label="序号" type="index"></el-table-column>
                        <el-table-column prop="archivesDataYear" label="年度" show-overflow-tooltip/>
                        <el-table-column prop="archivesBasicName" label="档案分类" show-overflow-tooltip/>
                        <el-table-column prop="queryCount" label="查看次数" show-overflow-tooltip/>
                        <el-table-column prop="printCount" label="打印次数" show-overflow-tooltip/>
                        <el-table-column prop="downloadCount" label="下载次数" show-overflow-tooltip/>
                        <el-table-column prop="borrowingFrequency" label="借阅次数（电子+实体）" width="200"
                                         show-overflow-tooltip/>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="searchForm.current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="searchForm.size"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'archivesBasicName'
                },
                pickerOptions: {
                    disabledDate(v) {
                        return v.getFullYear() > new Date().getFullYear() - 1
                    }
                },
                searchForm: {
                    archivesBasicDataId: '',
                    year: '',
                    current: 1,
                    size: 10,
                },
                loading: false,
                dataList: [],
                total: 0,
            }
        },
        mounted() {
            this.searchTree()
            this.search(1)
        },
        methods: {
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.treeData = []
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.treeData = res.data
                        this.treeData.unshift({
                            archivesBasicName: '全部',
                            id: '',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.$refs.myTree.setCurrentKey(this.treeData[0].id);
                        });
                    }
                    this.treeLoading = false
                })
            },
            // 选择档案分类
            selRecord(val) {
                this.searchForm.archivesBasicDataId = val.id
                this.searchForm.year = ''
                this.search(1)
            },
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                this.$axios(this.api.record.getArchivesDataUtilizeCountGroup, this.searchForm, 'get').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total)
                    }
                    this.loading = false
                })
            },
            // 导出
            exportData() {
                if (!this.dataList.length) {
                    this.$message.warning('暂无数据')
                    return
                }
                this.exportExcel(this.api.record.downLoadArchivesDataUtilizeCountGroup, {
                    archivesBasicDataId: this.searchForm.archivesBasicDataId,
                    archivesDataYear: this.searchForm.year
                }, '档案利用统计表')
            },
            // 重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped>
</style>
